import { addLocaleData } from 'react-intl';

import 'moment/locale/en-gb';
import antdLocale from 'antd/lib/locale-provider/en_GB';
import intlLocale from 'react-intl/locale-data/en';
import messages from './messages.po';

addLocaleData(intlLocale);

export default { antdLocale, intlLocale, messages };
